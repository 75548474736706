import React, {useEffect, useState} from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {ActionTooltip} from "../../components/shared/tooltip";
import {RefreshCwIcon} from "lucide-react";
import {Card} from "../../components/shared/card";
import {useLanguage} from "../../contexts/LanguageContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {getDateByLocale} from "../../lib/utils";
import DateRangePickerPopover from "../../components/shared/date-range-picker";
import {addDays} from "date-fns";
import {ChartData, LineChart, LineData} from "../../components/shared/ag-chart";

export const InstallMonitorAdmin: React.FC = () => {

    const {translate, language} = useLanguage();
    const axiosPrivate = useAxiosPrivate();

    const [loading, setLoading] = useState({
        init: true,
        statistic: true,
    });

    const chartTypes = ["SUCCESS", "VERIFY", "BAN"];

    const startDate = addDays(new Date(), 0);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const [rangeDate, setRangeDate] = useState<{ startDate: Date, endDate: Date }>({
        startDate: startDate,
        endDate: endDate
    });

    const [statisticData, setStatisticData] = useState<ChartData | null>(null);
    const [lastUpdateTime, setLastUpdateTime] = useState<string | null>('');

    const loadStatistics = (force: boolean = false) => {
        setLoading({
            ...loading,
            statistic: true
        });

        setStatisticData(null);

        axiosPrivate.post(`/statistic/admin/servers`, {
            start_date: rangeDate.startDate.toISOString(),
            end_date: rangeDate.endDate.toISOString(),
            force_update: force
        }).then((response) => {
            if (response.data.data.categories.length > 0) {
                let responseDataCategories = response.data.data.categories;
                let responseDataSeries = response.data.data.series;

                let result: ChartData = {
                    data: [],
                    series: []
                }

                responseDataCategories.forEach((time: number) => {
                    let existingItem = result.data.find((item: LineData) => item.dateTime === time);

                    if (!existingItem) {
                        result.data.push({dateTime: time});
                        existingItem = result.data[result.data.length - 1]; // Получаем только что добавленный элемент
                    }

                    Object.keys(responseDataSeries).forEach((key) => {
                        existingItem!![key.toLowerCase().replace(' ', '_')] =
                            responseDataSeries[key].find((v: { time_check: number }) => v.time_check === time)?.value ?? 0;

                    });
                });

                result.data.forEach((item) => {
                    // Суммируем все числовые значения
                    item.total = Object.entries(item)
                        .filter(([key]) => key !== "dateTime" && key !== "total") // Исключаем `dateTime` и `total`
                        .reduce((sum, [_, value]) => sum + (value as number), 0);

                    //Добавляем серии
                    Object.keys(item).forEach((key) => {
                        if (key !== "dateTime") {
                            let existingSeries = result.series.find((series) => series.yKey === key);

                            if (!existingSeries) {
                                result.series.push({
                                    type: 'line',
                                    xKey: 'dateTime',
                                    yKey: key,
                                    yName: key.toUpperCase().replace('_', ' '),
                                    interpolation: {type: 'smooth'},
                                    visible: key === 'total',
                                });
                            }
                        }
                    });
                });

                // Устанавливаем данные
                setLastUpdateTime(response.data.data.time_update);
                setStatisticData(result);
            } else {
                setStatisticData(null);
            }

            setLoading({
                ...loading,
                statistic: false
            });

        }).catch((error) => {
            console.log(error);
            setLoading({
                ...loading,
                statistic: false
            });
        });
    }

    const handleRefresh = () => {
        loadStatistics(true);
    }

    useEffect(() => {
        loadStatistics();

        return () => {

        };
    }, [rangeDate]);

    return (
        <Card>
            <div className={"grid grid-cols-3 w-full items-center"}>
                <div className={"flex justify-start"}>
                    <span className={"font-bold text-gray-dark dark:text-white"}>{translate('dashboard.install-monitor')}</span>
                </div>

                <div className={"flex justify-center"}>
                    <div className={"w-52"}>
                        <Select
                            disabled={loading.init || loading.statistic}
                            onValueChange={(value) => console.log(value)}
                            defaultValue={chartTypes[0]}
                        >

                            <SelectTrigger
                                className="bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none">
                                <SelectValue placeholder="Chart type"/>
                            </SelectTrigger>

                            <SelectContent>
                                {Object.values(chartTypes).map((type) => (
                                    <SelectItem
                                        key={type}
                                        value={type}
                                        className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                    >
                                        {type}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div
                    onClick={handleRefresh}
                    className={"cursor-pointer flex flex-row items-center gap-1 justify-end"}>
                    {loading.statistic ?
                        <div className={"w-20 h-2 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                        <ActionTooltip label={"Last update time"}>
                            <span className={"text-xs text-gray/50"}>{getDateByLocale(lastUpdateTime, language)}</span>
                        </ActionTooltip>
                    }
                    <ActionTooltip label={"Refresh"}>
                        <div
                            className={"p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white relative flex items-center transition-all"}
                        >
                            <RefreshCwIcon
                                size={20}
                                className={`text-gray-dark dark:text-white cursor-pointer ${loading.statistic ? 'animate-[spin_1.5s_infinite]' : ''}`}/>
                        </div>
                    </ActionTooltip>
                </div>
            </div>

            <DateRangePickerPopover
                initDates={rangeDate}
                onApply={(start, end) => setRangeDate({startDate: start, endDate: end})}
            />

            <LineChart chartData={statisticData} loading={loading.statistic} min={0}/>
        </Card>
    )
}
